import React from "react";
import TopNavbar from "../components/Nav/TeknisiKomputer";
import Header from "../components/Sections/TeknisiService/Header";
import Paket from "../components/Sections/TeknisiService/Paket";
import Price from "../components/Sections/TeknisiService/Price";
import Facility from "../components/Sections/TeknisiService/Facility";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function TeknisiService() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket />
      <Price />
      <Facility />
      <Contact/>
      <Footer />
    </>
  );
}