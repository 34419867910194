import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Projects() {
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <Wrapper id="projects">
      <div data-aos="fade-up" className="whiteBg">
        <div className="container">
          <HeaderInfo className="textCenter">
            <h1 className="font40 extraBold">BIAYA</h1>
          </HeaderInfo>
          <TableWrapper data-aos="fade-down" className="lightBg">
            <div className="tableContainer">
              <table className="styledTable">
                <tbody>
                  <tr>
                    <td>AUTOCAD 2 D  (15 x 2 jam)</td>
                    <td>Rp. 1.342.50</td>
                    <td>Jadwal bisa ditentukan sendiri.</td>
                  </tr>
                  <tr>
                    <td>kelas (10 x 2 jam)</td>
                    <td>Rp. 870.000</td>
                    <td>Kelas ditentukan PIKMI</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TableWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 20px 0; /* Adjust padding as needed */
  margin-bottom: 0; /* Remove bottom margin */
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  margin-top: 0; /* Remove top margin */

  .tableContainer {
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .styledTable {
    width: 100%;
    border-collapse: collapse;
  }

  .styledTable th, .styledTable td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e5e7eb;
  }

  .styledTable th {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    text-transform: uppercase;
  }

  .styledTable td {
    font-size: 14px;
    color: #374151;
  }

  .styledTable .editLink {
    color: #4f46e5;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: #4338ca;
    }
  }
`;
