import React from "react";
// Sections
import TopNavbar from "../components/Nav/DesainGrafis";
import Header from "../components/Sections/AutoCAD/Header";
import Price from "../components/Sections/AutoCAD/Price";
import Facility from "../components/Sections/AutoCAD/Facility";
import Paket from "../components/Sections/AutoCAD/Paket";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Perkantoran() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket />
      <Price />
      <Facility />
      <Contact />
      <Footer />
    </>
  );
}