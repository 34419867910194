import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                Materi Kursus Visual Basic di Bandung Privat  ( 6 x 2 jam) Rp.575.000,-
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Pertemuan 1</h1>
                <em className="font16">
                  Pengenalan Komponent (Object)
                </em>
              </SubHeaderInfo>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div data-aos="fade-right">
                <BlogBox
                  title="Materi Pembelajaran"
                  text="Project, Form, ToolBox, Properties, Kode Program, Event, Method, Module"
                  tag="Pikmi Rajawali"
                />
              </div>
            </div>
          </div>
          <div className="row">    
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Pertemuan 2</h1>
                <em className="font16">
                  Pengenalan Tipe Data, Variable dan Operator
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="STipe Data (Integer, Long, Single, Double, String, Currency, Variant), Variabel Buatan (Type Bentukan Sendiri), Operator Logika (And, Or, Xor)"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Pertemuan 3</h1>
                <em className="font16">
                  Kontrol Program
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Pencabangan GoSub, Return Pencabangan GoTo, Pencabangan On Error, Pencabangan On-GoSub dan On GoTo, Do Events, End Exit, Stop"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div data-aos="fade-down">
                  <SubHeaderInfo>
                    <h1 className="font20 extraBold">Pertemuan 4</h1>
                    <em className="font16">
                      Kondisional & Perulangan (Loop)
                    </em>
                  </SubHeaderInfo>
                </div>
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Statement If..Then…Else dan Select Case, Statement  Do ..Loop, Statament For ..Next"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Pertemuan 5</h1>
                <em className="font16">
                  Manajemen Database
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Menggunakan Visual Data manager(Create Database, Modify Database, Insert Index Data, Insert Tabel Data dlm file database),Mengaitkan Data Ke dlm Form dan Manipulasi Database (AddNew, Update, Delete, Edit, Refresh)"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;