import React from "react";
// Sections
import TopNavbar from "../components/Nav/TeknisiKomputer";
import Header from "../components/Sections/TeknisiKomputer/Header";
import Service from "../components/Sections/TeknisiKomputer/Service";
import Footer from "../components/Sections/Footer"


export default function TeknisiKomputer() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Service />
      <Footer />
    </>
  );
}