import React from "react";
// Sections
import TopNavbar from "../components/Nav/TeknisiKomputer";
import Header from "../components/Sections/LinuxNetwrokAdmin/Header";
import Paket from "../components/Sections/LinuxNetwrokAdmin/Paket";
import Price from "../components/Sections/LinuxNetwrokAdmin/Price";
import Facility from "../components/Sections/LinuxNetwrokAdmin/Facility";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function LinuxNetwrokAdmin() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket />
      <Price />
      <Facility />
      <Contact/>
      <Footer />
    </>
  );
}