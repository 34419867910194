import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Galeri/Header";
import Paket from "../components/Sections/Galeri/Paket";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Galery() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket />
      <Contact/>
      <Footer />
    </>
  );
}