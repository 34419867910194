import React from "react";
// Sections
import TopNavbar from "../components/Nav/DesainGrafis";
import Header from "../components/Sections/DesainGrafis/Header";
import Service from "../components/Sections/DesainGrafis/Service";
import Facility from "../components/Sections/DesainGrafis/Facility";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function DesainGrafis() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Service />
      <Facility />
      <Contact />
      <Footer />
    </>
  );
}