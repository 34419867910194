import React from "react";
// Sections
import TopNavbar from "../components/Nav/Pemrograman";
import Header from "../components/Sections/Php/Header";
import Paket from "../components/Sections/Php/Paket";
import Contact from "../components/Sections/Contact";
import Facility from "../components/Sections/FacilityForex";
import Footer from "../components/Sections/Footer"

export default function Perkantoran() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket/>
      <Facility/>
      <Contact />
      <Footer />
    </>
  );
}


