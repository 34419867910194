import React from "react";
// Sections
import TopNavbar from "../components/Nav/DesainGrafis";
import Header from "../components/Sections/AdobePhotoshop/Header";
import Paket from "../components/Sections/AdobePhotoshop/Paket";
// import Facility from "../components/Sections/AdobePhotoshop/Facility";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function AdobePhotoshop() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Paket />
      {/* <Facility /> */}
      <Contact />
      <Footer />
    </>
  );
}