import React,{useEffect} from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../../Elements/TestimonialSlider";
import ServiceBox from "../../Elements/ServiceBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Services() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <div data-aos="fade-down">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Kategori Desain</h1>
              <em className="font16">
              Desain Grafis & Video Editing
              </em>
            </HeaderInfo>
          </div>
          <div data-aos="fade-right">
            <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Adobe Illustrator"
                  subtitle="program editor grafis vektor terkemuka, dikembangkan dan dipasarkan oleh Adobe Systems."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Adobe Photoshop"
                  subtitle="systems yang dikhususkan untuk pengeditan foto/gambar dan pembuatan efek."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Auto CAD"
                  subtitle="untuk menggambar dan merancang dengan presisi tinggi dalam bidang arsitektur, teknik, dan desain."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox icon="printer" title="Corel Draw" subtitle="Editor grafik vektor yang dikembangkan oleh Corel," />
              </ServiceBoxWrapper>
            </ServiceBoxRow>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  text-align: center;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;