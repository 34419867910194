import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
      </div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                Linux Netwrok Admin.
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Materi</h1>
                <em className="font16">
                  list materi yang akan menjadi bahan pembelajaran
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="Pengantar Linux, distro primer Redhat, Debian dan Slakware, partisi root dan swap untuk virtual memori."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 2"
                    text="Me-resize partisi windows, pembuatan partisi root, swap, paket instalasi desktop, workstation, server, custom serta setting awal linux."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="Terminal atau konsole, perintah dasar CLI ( login, reboot, poweroff, cd, pwd, ls, mkdir, rmdir, berpindah antar konsole)"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 4"
                    text="Struktur dan penjelasan direktori bin, sbin, boot, dev, etc, mnt, mdia, lost+found, usr, var, tmp"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 5"
                    text="utility RPM , install, uninstal, upgrade, query informasi paket, dependency, instalasi dari source code(tar)."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 6"
                    text="TCP/IP, IP Public dan Private, IP kelas A,B,C, setting subnetmask 8bit samapai 29 bit."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 7"
                    text="SAMBA, instalasi, konfigurasi user, workgroup, computer name, sharing file, printer, koneksi windows-linux"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 8"
                    text="NFS, instalasi, konfigurasi file export, mounting dan utility NFS"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 9"
                    text="Konsep DNS, Name server, forward zone, reverse Zone, instalasi & konfigurasi, nslookup, dig"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 10"
                    text="FTP server instalasi & konfigurasi, Web server instalasi & konfigurasi"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;