import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Screens
import Landing from "./screens/Landing.jsx";
import Perkantoran from "./screens/Perkantoran.jsx";
import Akuntansi from "./screens/Akuntansi.jsx";
import Forex from "./screens/Forex.jsx";
import DesainGrafis from "./screens/DesainGrafis.jsx";
import AdobeIllustrator from "./screens/AdobeIllustrator.jsx";
import AdobePhotoshop from "./screens/AdobePhotoshop.jsx";
import AutoCAD from "./screens/AutoCAD.jsx";
import CorelDraw from "./screens/CorelDraw.jsx";
import TeknisiKomputer from "./screens/TeknisiKomputer.jsx";
import LinuxNetwrokAdmin from "./screens/LinuxNetwrokAdmin.jsx";
import TeknisiJaringan from "./screens/TeknisiJaringan.jsx";
import TeknisiService from "./screens/TeknisiService.jsx";


import Php from "./screens/Php.jsx";
import VisualBasic from "./screens/VisualBasic.jsx";
import Galeri from "./screens/Galeri.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/admPerkantoran" element={<Perkantoran />} />
          <Route path="/akuntansi" element={<Akuntansi />} />
          <Route path="/forex" element={<Forex />} />
          <Route path="/desainGrafis" element={<DesainGrafis />} />
          <Route path="/adobeIllustrator" element={<AdobeIllustrator />} />
          <Route path="/adobePhotoshop" element={<AdobePhotoshop />} />
          <Route path="/autoCad" element={<AutoCAD />} />
          <Route path="/corelDraw" element={<CorelDraw />} />
          <Route path="/php" element={<Php />} />
          <Route path="/visualBasic" element={<VisualBasic />} />
          <Route path="/TeknisiKomputer" element={<TeknisiKomputer />} />
          <Route path="/LinuxNetwrokAdmin" element={<LinuxNetwrokAdmin />} />
          <Route path="/TeknisiJaringan" element={<TeknisiJaringan />} />
          <Route path="/TeknisiService" element={<TeknisiService />} />
          <Route path="/galery" element={<Galeri />} />
        </Routes>
      </Router>
    </>
  );
}

