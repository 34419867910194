import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import FullButton from "../../Buttons/FullButton";
import TestimonialSlider from "../../Elements/PrivatePerkantoran";
import Diklat from "../../Elements/PaketDiklat";
import number1 from "../../../assets/icons/number-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                Windows, Microsoft Word, Microsoft Excel, Power Point.
                <br />
                Pertemuan 20 x 2 JAM
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Windows</h1>
                <em className="font16">
                  teknologi informasi mengacu pada sistem operasi yang dikembangkan oleh Microsoft Corporation
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="MATERI Penjelasan Desktop (icon, taskbar, background), penggunaan mouse (click kiri, click kanan, drag and drop, double click), control panel ( regional setting, mouse, date/time), windows explore (buat folder, copy/pindah/hapus/rubah file/folder), cara format disk, copy dari harddisk ke disk SASARAN Siswa memahami dan menguasai fungsi, cara penggunaan, untuk apa dan kapan diperlukan."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Microsoft Word</h1>
                <em className="font16">
                  perangkat lunak pengolah kata yang dikembangkan oleh Microsoft. 
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="MATERI : Untuk apa word, layar utama(toolbar, menubar, status bar), mengaktifkan/non aktifkan toolbar, tombol keyboard, page setup, mengetik, block (copy/move teks), tata letak naskah, (alignment, format font/teks), simpan/buka file pada posisi tertentu (folder tertentu) SASARAN Memahami akan fungsi word, dapat mengaktifkan dan menonaktifkan toolbar, mahir mengatur penggunaan kertas dan ukuran margin, mahir mengatur tata letak naskah"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 2"
                    text="MATERI: Identasi, Border and Shading, Bullets and numbering, insert page number, header and footer, cara mencetak, tugas (PR) SASARAN Mahir memberikan bingkai, penomoran naskah dan penomoran halaman"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="MATERI: Insert Symbol, Picture(Clipart), toolbar drawing Dapat menyisipkan simbol-simbol, menyisipkan dan mengatur picture dalam naskah, membuat struktur organisasi dan menyisipkan/membuat objek dari drawing"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 4"
                    text="MATERI: Equestions, Tabel dan Grafik SASARAN Dapat membuat rumus-rumus, mahir membuat berbagai tabel dan mentransfernya dalam bentuk grafik"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 5"
                    text="MATERI: Mail Merge (30 menit) dan Ujian ( 90 menit) SASARAN Mahir membuat surat masal dan evaluasi akhir"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Microsoft Excel</h1>
                <em className="font16">
                  perangkat lunak spreadsheet yang dikembangkan oleh Microsoft
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="MATERI Untuk apa Excel, Kolom/Baris/Sheet, Insert Kolom/Baris/Sheet, Delete Kolom/Baris/Sheet, Rename Sheet, Increase/Decrease Decimal, Currency, Percent, Formula Dasar (*, /, +, -), Auto Sum SASARAN Memahami fungsi Excel, Sel, Sheet dan operasinya, faham memformat data dan menggunakan formula dasar"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 2"
                    text="MATERI Fungsi Statistik, Copy, Move, Block data, Border Data, Border and shading, merge cell, wrap teks, data fill SASARAN Memahami fungsi statistik, operasi data, bingkai data, menggabung sel, melipat teks dalam sel, membuat data berurutan"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="MATERI Alamat sel, Fungsi Logika IF ( Tunggal dan Nested IF) Cara mencetak, Tugas (PR) Memahami cara kerja dan fungsi alamat sel, memahami fungsi IF dan bila dipergunakan, mengetahui bagaimana cara mencetak data di Excell"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 4"
                    text="MATERI Lanjutan fungsi IF, Lookuo, gabungan IF dan Lookup, Tugas SASARAN Lebih memahami fungsi IF, Lookup dan menggabungkan kedua fungsi tersebut"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 5"
                    text="MATERI Fungsi Database (Filter, Sort), Find/Replace data, Bekerja antar Sheet dan File Memahami fungsi database dan kapan penggunaannya, mencari dan mengganti data, mampu bekerja antar sheet dan file"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">                
                  <BlogBox
                    title="Pertemuan 6"
                    text="MATERI Pemantapan ( 30 Menit), Ujian ( 90 Menit) SASARAN Pemantapan dan Evaluasi"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Power Point</h1>
                <em className="font16">
                perangkat lunak presentasi yang dikembangkan oleh Microsoft
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="MATERI Fungsi Power Point, Apa itu Slide, membuat presentasi, Tambah Slide, Efek dan Animasi SASARAN Memahami fungsi dan kegunaan P.Point, mengolah slide, memberikan Efek dan animasi serta menjalankan slide"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 2"
                    text="MATERI Latihan, Menghubungkan slide berurutan slide berurutan dan tidak berurutan SASARAN Mampu menghubungkan slide dengan baik"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="MATERI Insert grafik ( 20 menit ), Ujian 100 menit SASARAN Mampu menyisipkan dan membuat grafik, Evaluasi"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Microsoft Access</h1>
                <em className="font16">
                sistem manajemen basis data relasional (RDBMS) yang dikembangkan oleh Microsoft
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="MATERI Pengenalan Ms. Access, Database, Table, Query, Form, Report dan Macro SASARAN mampu memahami struktur Ms. Access, Konsep Database, pembuatan Table, Layout Form dan penggunaan Macro"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 2"
                    text="MATERI Create Database, Table dan Quey SASARAN mampu membuat Database yang benar, membangun Table dalam Database dan mengakses table melalui bahasa Quey"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="MATERI Build Form dan Report SASARAN Mampu menampilkan isi Database (Table dan Query) ke dalam bentuk layout Form dan menyajikan dalam laporan untuk pencetakan"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-left">
                  <BlogBox
                    title="Pertemuan 4"
                    text="MATERI Latihan (20 menit) Test ( 100 Menit) SASARAN pemantapan dan evaluasi"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Kelas Privat Microsoft</h1>
              <em className="font16">
                Jadwal Pembelajaran bisa ditentukan oleh siswa/i yang bersangkutan.
                <br/>
                Pertemuan 20 x 2 jam, Biaya Rp. 950.000,-
              </em>
            </HeaderInfo>
          </div>
          <TestimonialSlider />
        </div>
      </div>
      <div className="whiteBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo className="textCenter">
            <h1 className="font40 extraBold">Kelas Privat Diklat Komputer</h1>
            <em className="font16">
              Jadwal Pembelajaran bisa ditentukan oleh siswa/i yang bersangkutan.
              <br/>
              Pertemuan 28 x 2 jam, Biaya Rp. 1.450.000,-
            </em>
          </HeaderInfo>
          <Diklat />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;