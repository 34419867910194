import React, { useEffect } from "react";
import styled from "styled-components";
import BlogBox from "../../Elements/BlogBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Wrapper id="service">
      <div className="lightBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter" style={{padding: '50px 0'}}>
              <h1 className="font40 extraBold">Kursus Teknisi Service</h1>
              <em className="font16">list materi pembelajaran</em>
              <div className="row">
                <div className="col-md-12">
                  <div data-aos="fade-right">
                    <StyledTable>
                      <thead>
                        <tr>
                          <th scope="col">Pertemuan</th>
                          <th scope="col">Materi</th>
                          <th scope="col">Detail</th>
                        </tr>                        
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pertemuan 1</td>
                          <td>Elektronika</td>
                          <td>Pengenalan Komponen Alat Ukur, penggunaan Alat Ukur,Pembacaan Kode/Nilai Komponen, pengukuran/Pengecekan Komponen</td>
                        </tr>
                        <tr>
                          <td>Pertemuan 2</td>
                          <td>MainBoard</td>
                          <td>Pengenalan Komponen MainBoard, Prinsip Kerja dan Blok Diagram MainBoard, Backup BIOS, Setting Jumper,Langkah-langkah,Service MainBoard</td>
                        </tr>
                        <tr>
                          <td>Pertemuan 3</td>
                          <td>Harddisk</td>
                          <td>Pengenalan Komponen HDD, Prinsip Kerja dan Blok Diagram HDD, Low Level Format – Ontrack
                              Ontrack IBM, PQ Magic, Langkah-langkah Service HDD
                            </td> 
                        </tr>
                        <tr>
                          <td>Pertemuan 4</td>
                          <td>CD ROM</td>
                          <td>Pengenalan Komponen CD ROM, Prinsip Kerja dan Block Diagram CD ROM, Langkah-langkah Service CD ROM</td>
                        </tr>
                        <tr>
                          <td>Pertemuan 5</td>
                          <td>Monitor</td>
                          <td>Prinsip Karja Rangkaian dasar Horizontal, Pengenalan Komponen Horizontal,Langkah-langkah Service</td>
                        </tr>
                        <tr>
                          <td>Pertemuan 6</td>
                          <td>Monitor</td>
                          <td>Kerja Rangkaian Dasar Vertical, Pengenalan Komponen Vertical, Langkah-langkah ServiceDetail  : Prinsip Kerja Rangakaian Dasar RGB, Pengenalan Komponen RGB, Langkah-langkah Service</td>
                        </tr>
                        <tr>
                          <td>Pertemuan 7</td>
                          <td>Printer</td>
                          <td>Pengenalan komponen Printer, Prinsip Kerja dan Blok Diagram Printer, Langkah-langkah Service</td>
                        </tr>
                      </tbody>
                    </StyledTable>
                  </div>
                </div>
              </div>
            </HeaderInfo>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }
`;
