import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}></div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Materi</h1>
              <em className="font16">
                Adobe Corel Draw.
              </em>
            </HeaderInfo>
          </div>
          <FlexContainer>
            {sessions.map((session, index) => (
              <Column key={index} data-aos="fade-down">
                <SubHeaderInfo>
                  <h1 className="font20 extraBold">{session.title}</h1>
                  <em className="font16">{session.description}</em>
                </SubHeaderInfo>
              </Column>
            ))}
          </FlexContainer>
        </div>
      </div>
    </Wrapper>
  );
}

const sessions = [
  { title: "Sesi 1", description: "Pengantar Aplikasi Corel Draw, Fungsi Program, Starting Program, Menu dan Tool Program, Bagian-bagian layar penyuntingan" },
  { title: "Sesi 2", description: "Penggunaan Arrange (Transform, Align dan Distribute, Order, Shaping)" },
  { title: "Sesi 3", description: "akuntansi terampil ini meliputi Perkreditan, piutang, surat berharga" },
  { title: "Sesi 4", description: "akuntansi terampil ini meliputi Perkreditan, piutang, surat berharga" },
  { title: "Sesi 5", description: "akuntansi terampil ini meliputi Perkreditan, piutang, surat berharga" },
  { title: "Sesi 6", description: "akuntansi terampil ini meliputi Perkreditan, piutang, surat berharga" }
];

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const SubHeaderInfo = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
  em {
    display: block;
    margin-top: 10px;
    line-height: 1.6;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
`;

