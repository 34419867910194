import React,{useEffect} from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../../Elements/TestimonialSlider";
import ServiceBox from "../../Elements/ServiceBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Services() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <div data-aos="fade-down">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Kategori Teknik Komputer</h1>
              <em className="font16">
              Operation system , Jaringan , dan Service
              </em>
            </HeaderInfo>
          </div>
          <div data-aos="fade-right">
            <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Linux Netwrok Admin"
                  subtitle="Bertanggung jawab untuk mengelola, mengkonfigurasi, dan memelihara jaringan berbasis Linux, memastikan konektivitas yang aman dan efisien."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Teknisi Jaringan"
                  subtitle="Bertanggung jawab untuk memasang, memelihara, dan memperbaiki jaringan komputer, termasuk pengaturan kabel, konfigurasi perangkat keras, dan troubleshooting."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Teknisi Service"
                  subtitle="Melakukan perbaikan dan pemeliharaan perangkat keras dan perangkat lunak komputer, serta memastikan sistem berfungsi dengan baik dan efisien."
                />
              </ServiceBoxWrapper>
            </ServiceBoxRow>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin : 0 auto;
  padding: 80px 0;
  text-align: center;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;