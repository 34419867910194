 
import React, { useEffect } from "react";
import styled from "styled-components";
import ClientSlider from "../../Elements/ClientSlider";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Projects() {
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <Wrapper id="projects">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div data-aos="fade-up" className="whiteBg">
        <div className="container">
          <HeaderInfo className="textCenter" style={{padding: '50px 0'}}>
            <h1 className="font40 extraBold">Fasilitas</h1>
            <em className="font16">
              utamakan kenyamanan dalam belajar
            </em>
          </HeaderInfo>
          <CardsOfFasility data-aos="fade-right" className="textCenter">
            <div className="card-container">
              <div className="card">
                <div className="card-content">
                  <h2>Modul Pelatihan</h2>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <h2>Bers Sertifikat (ijin dari Depnaker)</h2>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <h2>Ujian Gratis</h2>
                </div>
              </div>
              <div className="card">
                <div className="card-content">
                  <h2>Fasilitas internet dengan Bandwidth up to 1 Mb/s</h2>
                </div>
              </div>
            </div>
          </CardsOfFasility>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CardsOfFasility = styled.div`
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 45%;
    margin: 1rem;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;  /* Memastikan konten card tersusun secara vertikal */
    align-items: center;
    justify-content: center; 
    text-align: center; 
    height: 200px;  /* Memberikan tinggi tetap untuk setiap card */
  }

  .card:hover {
    transform: scale(1.05);
  }

  .card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column; /* Membuat konten vertikal */
    justify-content: center; /* Pusatkan konten secara vertikal */
    height: 100%;  /* Membuat card-content memenuhi card */
  }

  .card-content h2 {
    margin-top: 0;
    font-size: 1.5rem;
    flex: 1;  /* Membuat h2 memenuhi ruang yang tersedia */
    display: flex;
    align-items: center; /* Pusatkan teks secara vertikal dalam h2 */
    justify-content: center; /* Pusatkan teks secara horizontal dalam h2 */
  }

  @media (max-width: 860px) {
    .card-container {
      flex-direction: column; 
    }

    .card {
      width: 100%; 
    }
  }
`;
