import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import number1 from "../../assets/icons/number-1.png"
import number2 from "../../assets/icons/number-2.png";
import number3 from "../../assets/icons/number-3.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Projects() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-down">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Prosedure Pendaftaran</h1>
              <em className="font16">
                Bergabung dengan Pikmi Rajawali sangat mudah!
              </em>
            </HeaderInfo>
          </div>
          <div data-aos="fade-up">
            <div className="row textCenter">
              <div className="col-md-4">
                <LogoWrapper>
                  <img src={number1} alt="client logo" width="40px"/>
                  <p className="font13">
                    Mengisi Formulir Pendaftaran Online atau datang langsung ke PIKMI RAJAWALI.
                  </p>
                </LogoWrapper>
              </div>
              <div className="col-md-4">
                <LogoWrapper>
                  <img src={number2} alt="client logo" width="40px"/>
                  <p className="font13">
                    Membawa tanda bukti pembayaran pada saat datang ke PIKMI RAJAWALI.
                  </p>
                </LogoWrapper>
              </div>
              <div className="col-md-4">
                <LogoWrapper>
                  <img src={number3} alt="client logo" width="40px"/>
                  <p className="font13">
                    Membawa Pas Foto berwarna 3×4 = 2 lembar saat datang ke PIKMI RAJAWALI.
                  </p>
                </LogoWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  margin-top:40px;
  margin-bottom:60px;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;
