import React,{useEffect} from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function TestimonialSlider() {
  useEffect(() => {
    AOS.init();
  }, [])
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <div data-aos="fade-right">
            <TestimonialBox
              text="Microsoft Word"
              author="Pikmi Rajawali"
            />
          </div>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <div data-aos="fade-left">
            <TestimonialBox
              text="Microsoft Excel"
              author="Pikmi Rajawali"
            />
          </div>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <div data-aos="fade-right">
            <TestimonialBox
              text="Power Point"
              author="Pikmi Rajawali"
            />
          </div>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <div data-aos="fade-left">
            <TestimonialBox
              text="Microsoft Access"
              author="Pikmi Rajawali"
            />
          </div>
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
