import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Wrapper id="service">
      <div className="lightBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter" style={{padding: '50px 0'}}>
              <h1 className="font40 extraBold">MATERI kursus Autocad 2D</h1>
              <em className="font16">list materi pembelajaran</em>
              <div className="row">
                <div className="col-md-12">
                  <div data-aos="fade-right">
                    <StyledTable>
                      <thead>
                        <tr>
                          <th scope="col">Materi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Dasar – dasar Autocad 2D, Sistem Koordinat, Manajemen Layer, Akses Perintah, View-Toolbars, Limits dan Format-Drawing Limits, Unit dan Format-Unit, Tools-Drawing Aids, Grid, Snap, Otho, Zoom, Pan, Tools-Object Snap Settings.</td>
                        </tr>
                        <tr>
                          <td>Menggambar Bentuk-bentuk sederhana, dan pemahaman konsep-konsep dasar Drawing pada Autocad 2000</td>
                        </tr>
                        <tr>
                          <td>Menggambar Obyek Aplikasi sederhana</td>
                        </tr>
                        <tr>
                          <td>Konsep Blok (Obyek yang tersimpan pada Database File) Wblok (Duplikasi Obyek yang dapat digunakan pada file yang berbeda)</td>
                        </tr>
                        <tr>
                          <td>Pemahaman penggambaran sisi dimensi</td>
                        </tr>
                        <tr>
                          <td>Cetak (Cetak Layout, Cetak Skala, Ctak Hideplot)</td>
                        </tr>
                        <tr>
                          <td>Presentasi Autocad : Shading, Rendering (Light, Mapping, BackGorund, Materials, Lanscape</td>
                        </tr>
                      </tbody>
                    </StyledTable>
                  </div>
                </div>
              </div>
            </HeaderInfo>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }
`;
