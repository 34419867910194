import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import FullButton from "../../Buttons/FullButton";
import TestimonialSlider from "../../Elements/PrivatePerkantoran";
import Diklat from "../../Elements/PaketDiklat";
import number1 from "../../../assets/icons/number-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                Adobe Photoshop.
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Materi</h1>
                <em className="font16">
                  list materi yang akan menjadi bahan pembelajaran
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="Mempelajari bagian-bagian Adobe Photoshop, menguasai alat/Toolbox dalam Adobe Photoshop Basic, mempelajari Palette/Panel dalam Adobe Photoshop Basic, mengubah ukuran Canvas Area dan Resolusi dalam sebuah foto dan mengatur resolusi foto. Mewarnai background foto."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 2"
                    text="Memindahkan foto/objek ke dalam file lain, mengubah ukuran dan memutar foto/objek, memindahkan/mengubah background dan membuat berbagai macam objek secara singkat."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="Menghapus/menghilangkan watermark/objek dalam foto secara singkat dan memperbesar ukuran foto/gambar dengan tetap menjaga kualitas dari foto/gambar aslinya."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 4"
                    text="Modifikasi bentuk keseluruhan foto/objek, membuat variasi teks dan membuat album foto yang menarik (pernikahan, ulang tahun dan keluarga) secara instan."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 5"
                    text="Mempelajari Efek-Efek Layer (Drop Shadow. Bevel & Emboss, Inner Glow & Outer Glow, dll) dan membuat Banner."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 6"
                    text="Membuat Billboard , mempelajari Adjustment Layer (Level, Curve, Color Balance, Black & White, Exposure, Hue/Saturation, Photo Filter, Gradient Map, Replace Color, Shadow/Highlight, HDR Toning, dll)."                     tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 7"
                    text="Mempelajari Channel (proces color : RGB & CMYK), manipulasi Warna (foto hitam putih menjadi berwarna dan mengubah warna foto dengan warna lain) dan mempertajam objek foto."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 8"
                    text="Menghilangkan Noise (butiran debu) pada foto, mempertajam warna foto, manipulasi pencahayaan (menerangkan bagian foto yang gelap atau sebaliknya, membuat pencahayaan pijar/lens flare, dll), mempelajari Filter-Filter Galeri (Paint Daubs, Rough Pastels, texturizer, dll) dan mempelajari Add Layer/Add Vector Mask (Masking)."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 9"
                    text="Manipulasi foto (membuat hujan salju, membuat hujan air, membuat efek transparan, membuat efek terbakar, membuat awan buatan, membuat efek bergerak cepat pada foto (panning), menambahkan sebuah objek foto, efek lensa fokus (dept of field), membuat efek zooming, memberikan mulut dalam sebuah tomat, mengubah kepala binatang dengan binatang lain, membuat luka gores di wajah, manipulasi mengubah buaya menjadi sepatu, memanjangkan tangan, memasukkan bayi dalam gelas, bayi peri, efek horor (drakula), efek fantasi, dll). Make Up Foto (Menghilangkan jerawat, menghaluskan kulit wajah, blush on, mengubah warna bibir, mempercantik bibir agar tampak seksi, mengganti warna mata, mempertajam mata, menebalkan alis, eye liner, mengganti warna rambut, memutihkan gigi, mengganti gigi yang tanggal/ompong, mengganti keseluruhan gigi, memancungkan hidung, memendekkan dagu yang terlalu panjang, memasang kumis/jenggot palsu, mengganti warna baju, dll) dan evaluasi keseluruhan materi pembelajaran"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;