import React, {useEffect} from "react";
import styled from "styled-components";
import BlogBox from "../../Elements/BlogBox";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                Adobe Illustrator.
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Materi</h1>
                <em className="font16">
                  list materi yang akan menjadi bahan pembelajaran
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 1"
                    text="Siswa akan Mempelajari bagian-bagian Adobe Illustrator, kemudian penguasaan peralatan (Toolbox) , dan mempelajari Palette/Panel."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 2"
                    text="Membuat pewarnaan Proses Color (CMYK), pewarnaan gradasi, desain Logo 1 Dimensi. Modifikasi objek dengan teknik Pathfinder yang menakjubkan dan Mempelajari Pattern (tekstur) serta Membuat desain Kartu Nama."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 3"
                    text="Membuat desain Kop Surat, desain Amplop, dan desain Stiker."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 4"
                    text="Membuat desain Flyer, desain Print Ad, mempelajari tehnik menggambar ilustrasi/orang/ornamen/motif batik dengan Pen Tool dan mempelajari Teknik Masking (gambar masuk ke dalam objek yang kita buat)."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 5"
                    text="Mempelajari efek/filter, untuk modifikasi objek menjadi bentuk yang luar biasa dan membuat objek 3 Dimensi."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Pertemuan 6"
                    text="Membuat desain Brosur, desain Poster, dan evaluasi semua materi pembelajaran"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;