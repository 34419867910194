import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import FullButton from "../../Buttons/FullButton";
import TestimonialSlider from "../../Elements/PrivatePerkantoran";
import Diklat from "../../Elements/PaketDiklat";
import number1 from "../../../assets/icons/number-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo className="textCenter">
            <h1 className="font40 extraBold">Materi Pembelajaran</h1>
            <em className="font16">
              Jadwal Pembelajaran bisa ditentukan oleh siswa/i yang bersangkutan.
            </em>
          </HeaderInfo>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <th scope="col">Materi 1</th>
                  <th scope="col">Materi 2</th>
                  <th scope="col">Materi 3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Account">Pengenalan HTML</td>
                  <td data-label="Due Date">Fungsi HTML</td>
                  <td data-label="Amount"> - </td>
                </tr>
                <tr>
                  <td scope="row" data-label="Account">HTML Lanjutan 1</td>
                  <td data-label="Due Date">Table Data</td>
                  <td data-label="Amount">Form HTML</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Account">HTML Lanjutan 2</td>
                  <td data-label="Due Date">Penggabungan Table Dan Form</td>
                  <td data-label="Amount">-</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Acount">CSS Dasar</td>
                  <td data-label="Due Date">Metode Penggunaan CSS</td>
                  <td data-label="Amount">-</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Acount">CSS Lanjutan</td>
                  <td data-label="Due Date">Studi Kasus</td>
                  <td data-label="Amount">-</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Acount">CSS Visibility</td>
                  <td data-label="Due Date">Javascript Dasar</td>
                  <td data-label="Amount">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Studi Kasus</h1>
              <em className="font16">
                BIAYA – Privat (20 x 2 jam ) :   Rp 1.431.000
              </em>
            </HeaderInfo>
          </div>
          <div class="row">
            <div className="col-md-6">
              <div data-aos="fade-down">
                <SubHeaderInfo className="textCenter">
                  <h1 className="font20 extraBold">Web Statis</h1>
                  <em className="font16">
                    merupakan Studi Kasus Penggunaan HTML dan CSS membentuk suatu Web statis
                  </em>
                </SubHeaderInfo>
              </div>
            </div>
            <div className="col-md-6">
              <div data-aos="fade-down">
                <SubHeaderInfo className="textCenter">
                  <h1 className="font20 extraBold">Web Dinamis</h1>
                  <em className="font16">
                  merupakan pengenalan PHP, Web dinamis, DBMS, beserta Studi kasus nya
                  </em>
                </SubHeaderInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;