import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../Elements/BlogBox";
import FullButton from "../../Buttons/FullButton";
import TestimonialSlider from "../../Elements/PrivatePerkantoran";
import Diklat from "../../Elements/PaketDiklat";
import number1 from "../../../assets/icons/number-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">
                AK Dasar 1, AK Dasar 2, AK Mahir, MYOB.
              </em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Akuntansi Dasar 1</h1>
                <em className="font16">
                  akuntansi dasar 1 ini meliputi persamaan akuntansi
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Prinsip dan aturan pencatatan akuntansi,  Persamaan Akuntansi dan Jurnal Umum,  Buku Besar dan Neraca Saldo,  Jurnal Penyesuaian,  Worksheet (Kertas Kerja),  Laporan Keuangan (Neraca, Laporan laba rugi, laporan perubahan modal),  Jurnal Penutup,  Laporan Keuangan Perusahaan Jasa dan Dagang (General Acounting),  Laporan Keuangan Industri Manufaktur (General Accounting), membuat jurnal akuntansi,  buku besar,  serta Neraca."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Akuntansi Dasar 2</h1>
                <em className="font16">
                  akuntansi dasar 2 ini meliputi Kas dan Bank
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Surat Surat Berharga (Investasi Jangka Pendek),  Piutang Usaha dan Wesel,  Persediaan (Inventory),  Investasi Jangka Panjang,  Activa Tetap Berwujud,  Activa Tidak Berwujud,  Hutang jangka pendek,  Hutang Jangka Panjang,  Modal dan Laba di Tahan Laporan keuangan,  rekening dan neraca."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Akuntansi Mahir</h1>
                <em className="font16">
                  akuntansi terampil ini meliputi Perkreditan,  piutang,  surat berharga
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="melibatkan pemahaman mendalam tentang berbagai konsep dan praktik, termasuk prinsip-prinsip akuntansi dasar seperti persamaan akuntansi, konsep debit dan kredit, serta penyusunan laporan keuangan seperti neraca, laporan laba rugi, laporan arus kas, dan laporan perubahan ekuitas."
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">MYOB</h1>
                <em className="font16">
                  komputerisasi Accounting.
                </em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="MATERI Fungsi Power Point, Apa itu Slide, membuat presentasi, Tambah Slide, Efek dan Animasi SASARAN Memahami fungsi dan kegunaan P.Point, mengolah slide, memberikan Efek dan animasi serta menjalankan slide"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Perpajakan</h1>
              <em className="font16">
              </em>
            </HeaderInfo>
          </div>
          <div class="row">
            <div className="col-md-6">
              <div data-aos="fade-down">
                <SubHeaderInfo className="textCenter">
                  <h1 className="font20 extraBold">Brevet A</h1>
                  <em className="font16">
                    pembahasan dasar sampai dengan ketentuan perpajakan (pajak penghasilan) Orang Pribadi (OP).
                  </em>
                </SubHeaderInfo>
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Materinya meliputi Ketentuan umum (tentang tatacara) Perpajakan (KUP), PBB, BPHTB, Bea Materai sampai Pajak Penghasilan (PPh) Orang Pribadi (OP) Adapun isi materi tersebut antara lain"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div data-aos="fade-down">
                <SubHeaderInfo className="textCenter">
                  <h1 className="font20 extraBold">Brevet B</h1>
                  <em className="font16">
                    pembahasan dasar sampai menengah dengan pembahasan ketentuan perpajakan Badan / perusahaan.
                  </em>
                </SubHeaderInfo>
                <div data-aos="fade-right">
                  <BlogBox
                    title="Materi Pembelajaran"
                    text="Materinya meliputi Pemotongan dan Pemungutan (PPh Pot-Put) Pajak Penghasilan Pasal 21, Pasal 15, Pasal 23, Pasal 25, Pasal 26, Pasal 4 ayat (2) dan sebagainya, Pajak Pertambahan Nilai (PPN) baik 1111 maupun 1107 PUT"
                    tag="Pikmi Rajawali"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo className="textCenter">
            <h1 className="font40 extraBold">Jadwal Pembelajaran</h1>
            <em className="font16">
              Jadwal Pembelajaran bisa ditentukan oleh siswa/i yang bersangkutan.
            </em>
          </HeaderInfo>
          <div className="row">
            <table>
              <thead>
                <tr>
                  <th scope="col">Paket</th>
                  <th scope="col">Materi</th>
                  <th scope="col">Pertemuan</th>
                  <th scope="col">Biaya</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Account">Akuntansi Dasar 1</td>
                  <td data-label="Due Date">Pers.Akun, Jurnal. Buku Besar, Neraca</td>
                  <td data-label="Amount">10 x 2 jam</td>
                  <td data-label="Period">Rp. 950.000</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Account">Akuntansi Dasar II</td>
                  <td data-label="Due Date">Lap.Keuangan, Rekening, Neraca</td>
                  <td data-label="Amount">10 x 2 jam</td>
                  <td data-label="Period">Rp. 950.000</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Account">Akuntansi Mahir</td>
                  <td data-label="Due Date">Perkreditan, Piutang, Surat Berharga</td>
                  <td data-label="Amount">10 x 2 jam</td>
                  <td data-label="Period">Rp. 950.000</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Acount">Pajak Brevet A & B</td>
                  <td data-label="Due Date">Pajak Perseorangan</td>
                  <td data-label="Amount">10 x 2 jam</td>
                  <td data-label="Period">Rp. 3.850.000</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Acount">Akuntansi Pajak & MYOB</td>
                  <td data-label="Due Date">-</td>
                  <td data-label="Amount">78 x 2 Jam</td>
                  <td data-label="Period">Rp. 6.850.000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;