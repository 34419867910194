

import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import styled from "styled-components";
import 'react-image-lightbox/style.css';

const images = [
  'https://via.placeholder.com/600/92c952',
  'https://via.placeholder.com/600/771796',
  'https://via.placeholder.com/600/24f355',
  'https://via.placeholder.com/600/d32776',
  'https://via.placeholder.com/600/f66b97',
  'https://via.placeholder.com/600/92c952',
  'https://via.placeholder.com/600/771796',
  'https://via.placeholder.com/600/24f355',
  'https://via.placeholder.com/600/d32776',
  'https://via.placeholder.com/600/f66b97',
  'https://via.placeholder.com/600/92c952',
  'https://via.placeholder.com/600/771796',
  'https://via.placeholder.com/600/24f355',
  'https://via.placeholder.com/600/d32776',
  'https://via.placeholder.com/600/f66b97'
];

const PhotoAlbum = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Wrapper id="projects">
        <div className="lightBg" style={{ padding: "50px 0" }}>
        </div>
        <div data-aos="fade-up" className="whiteBg">
          <div className="container">
            <HeaderInfo className="textCenter" style={{padding: '50px 0'}}>
              <h1 className="font30 extraBold">Dokumentasi Pikmi Rajwali</h1>
            </HeaderInfo>
          </div>
        </div>
        <div className="container textCenter">
          <div>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                onClick={() => { setPhotoIndex(index); setIsOpen(true); }}
                alt={`Thumbnail ${index}`}
                style={{ width: '150px', height: '150px', margin: '10px', cursor: 'pointer' }}
              />
            ))}
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default PhotoAlbum;



const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CardsOfFasility = styled.div`
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 45%;
    margin: 1rem;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;  /* Memastikan konten card tersusun secara vertikal */
    align-items: center;
    justify-content: center; 
    text-align: center; 
    height: 200px;  /* Memberikan tinggi tetap untuk setiap card */
  }

  .card:hover {
    transform: scale(1.05);
  }

  .card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column; /* Membuat konten vertikal */
    justify-content: center; /* Pusatkan konten secara vertikal */
    height: 100%;  /* Membuat card-content memenuhi card */
  }

  .card-content h2 {
    margin-top: 0;
    font-size: 1.5rem;
    flex: 1;  /* Membuat h2 memenuhi ruang yang tersedia */
    display: flex;
    align-items: center; /* Pusatkan teks secara vertikal dalam h2 */
    justify-content: center; /* Pusatkan teks secara horizontal dalam h2 */
  }

  @media (max-width: 860px) {
    .card-container {
      flex-direction: column; 
    }

    .card {
      width: 100%; 
    }
  }
`;