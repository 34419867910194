import React, { useEffect } from "react";
import styled from "styled-components";
import BlogBox from "../../Elements/BlogBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, []);

  const pertemuanData = [
    {
      title: "Pertemuan 1",
      items: [
        "1. Teori Jaringan",
        "2. Teori Dasar",
        "3. Topologi Jaringan",
        "4. Pengenalan Hardware Jaringan",
        "   - Card Ethernet",
        "   - Switch",
        "   - Konektor RJ45",
        "   - Kabel UTP",
        "   - Modem Router"
      ]
    },
    {
      title: "Pertemuan 2",
      items: [
        "1. Perakitan Jaringan",
        "2. Pengkabelan Jaringan",
        "   - Straight Through",
        "   - Crossover",
        "   - Roll Over",
        "   - Standar TIA/EIA 568B dan 568A",
        "3. Setting Jaringan Peer to Peer",
        "   - Instalasi driver ethernet",
        "   - Setting IP address",
        "   - Set Workgroup",
        "   - Ping",
        "   - Sharing file, folder, printer"
      ]
    },
    {
      title: "Pertemuan 3",
      items: [
        "1. Teori TCP/IP",
        "2. Konfigurasi IP Address & SubNet",
        "3. IP Private dan IP Public",
        "4. Kelas-kelas IP address A, B, C",
        "5. DNS",
        "6. Gateway"
      ]
    },
    {
      title: "Pertemuan 4",
      items: [
        "1. Praktek TCP/IP",
        "2. Transfer File",
        "3. Chatting",
        "4. Sharing Desktop",
        "5. Instalasi Billing Warnet",
        "   - Setting server & klien",
        "   - Set harga dan operator"
      ]
    },
    {
      title: "Pertemuan 5",
      items: [
        "1. Instalasi Windows 2003 Server",
        "2. Praktek Penginstalan Windows 2003 Server",
        "3. Konfigurasi Dasar Windows 2003 Server",
        "4. Instalasi Active Directory (Domain Controller) pada Windows 2003 Server",
        "5. Setting Koneksi Client ke 2003 Server",
        "6. Administrasi Jaringan Windows 2003 Server",
        "   - User Management",
        "   - Sharing Permission",
        "   - DHCP Server"
      ]
    },
    {
      title: "Pertemuan 6",
      items: [
        "1. Instalasi Mikrotik",
        "2. Konfigurasi Dasar",
        "   - Interface Internet dan Intranet",
        "   - IP, Subnetmask, Gateway Default",
        "3. Pengaturan Queue dan Bandwidth Management",
        "   - Upload dan Download"
      ]
    }
  ];

  return (
    <Wrapper id="service">
      <div className="lightBg" style={{ padding: "50px 0" }}></div>
      <div className="whiteBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Program Pelatihan</h1>
              <em className="font16">Teknisi Komputer.</em>
            </HeaderInfo>
          </div>
          <div className="row">
            <div data-aos="fade-down">
              <SubHeaderInfo>
                <h1 className="font20 extraBold">Materi</h1>
                <em className="font16">List materi yang akan menjadi bahan pembelajaran</em>
              </SubHeaderInfo>
            </div>
            <div className="row">
              {pertemuanData.map((pertemuan, index) => (
                <div className="col-md-6" key={index}>
                  <div data-aos="fade-right">
                    <BlogBox
                      title={pertemuan.title}
                      text={pertemuan.items.map((item) => (
                        <div key={item} style={{ marginBottom: "8px" }}>{item}</div>
                      ))}
                      tag="Pikmi Rajawali"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const SubHeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
