import React,{useEffect} from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import TestimonialSlider from "../Elements/TestimonialSlider";
import ServiceBox from "../Elements/ServiceBox";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Services() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        {/* <div className="container">
          <ClientSlider />
        </div> */}
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <div data-aos="fade-down">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Service Kami</h1>
              <em className="font16">
                Belajar Komputer dengan Mudah dan Menyenangkan
              </em>
            </HeaderInfo>
          </div>
          <div data-aos="fade-right">
            <ServiceBoxRow className="flex">
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Adm Perkantoran"
                  subtitle="Manajemen dan koordinasi kegiatan sehari-hari di lingkungan kantor. "
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Akuntansi"
                  subtitle="Pencatatan, pengelolaan, dan pelaporan transaksi keuangan suatu organisasi atau individu."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox
                  icon="printer"
                  title="Desain Grafis"
                  subtitle="Seni dan praktik merencanakan dan memproyeksikan ide dan pengalaman dengan konten visual dan tekstual."
                />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox icon="printer" title="Pemrograman" subtitle="Proses menulis, menguji, dan memelihara kode yang memungkinkan perangkat lunak dan aplikasi berfungsi." />
              </ServiceBoxWrapper>
              <ServiceBoxWrapper>
                <ServiceBox icon="printer" title="Teknisi Komputer" subtitle="Memelihara, memperbaiki, dan menginstal perangkat keras dan perangkat lunak komputer." />
              </ServiceBoxWrapper>
            </ServiceBoxRow>
          </div>
        </div>
        <div className="lightBg" style={{padding: '50px 0'}}>
          <div className="container">
            <div data-aos="fade-down">
              <HeaderInfo className="textCenter">
                <h1 className="font40 extraBold">Program Khusus</h1>
                <em className="font16">
                  Ikuti Program Khusus dari kami ” PAKET 1 Bulan “
                  <br />
                  dengan total waktu pelatihan selama 1 Bulan. Total Biaya sampai selesai Rp. 950 ribu. 
                  Program selalu diadakan setiap bulan pada tanggal pertengahan (15 – 18).
                </em>
              </HeaderInfo>
            </div>
            <div data-aos="fade-up">
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  text-align: center;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;