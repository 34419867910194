import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Paket() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Wrapper id="service">
      <div className="lightBg">
        <div className="container">
          <div data-aos="fade-up">
            <HeaderInfo className="textCenter">
              <h1 className="font40 extraBold">Forex & Trading</h1>
              <em className="font16">
                list materi pembelajaran
              </em>
            </HeaderInfo>
          </div>
          <div className="row textCenter">
            <div className="col-md-12">
              <div data-aos="fade-right">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Materi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Due Date">Pengantar Forex</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Broker teregulasi lokal Dan Internasional (NFA, CFTC, ASIC, MFID) pair mata uang, Metatrader 4</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Perkreditan, Piutang, Surat Berharga</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Membuka akun demo, instalasi indikator, robot, template, entri buy, sell, take profit, stop loss</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">tipe trading: Scalping, Intraday, Swing & Position trader, Analisa trend Scalping, Intraday, Swing & Position</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Money management, risk reward ratio, Lot, psikologi trading</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Analisa Fundamental & news Trading</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Pembukaan akun real, upload dokumen ID & bill utility, deposit, withdrawal</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Sinyal forex (software generator sinyal, prediksi situs penghasil sinyal BUY, SELL, Auto trading)</td>
                    </tr>
                    <tr>
                      <td data-label="Due Date">Strategi trading, Evaluasi</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;